import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Linden({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-container">
        <div className="formkit-card">
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{ content: "event name" }}
          />
          <Elements.Region name="subheader" className="formkit-subheader">
            <Elements.Content
              defaults={{
                content: "City • State"
              }}
            />
          </Elements.Region>
          <Elements.Heading
            name="about_label"
            group="label"
            tag={"h2"}
            className="formkit-content-label"
            defaults={{ content: "about" }}
          />
          <Elements.Region
            name="about_content"
            group="content"
            className="formkit-content"
          >
            <Elements.Content
              defaults={{
                content: "Provide some more detail about what the event is"
              }}
            />
          </Elements.Region>
          <Elements.Heading
            name="hours_label"
            group="label"
            tag={"h2"}
            className="formkit-content-label"
            defaults={{ content: "hours" }}
          />
          <Elements.Region
            name="hours_content"
            group="content"
            className="formkit-content"
          >
            <Elements.Content
              defaults={{
                content: "Provide more info about when the event is"
              }}
            />
          </Elements.Region>
          <Elements.Heading
            name="location_label"
            group="label"
            tag={"h2"}
            className="formkit-content-label"
            defaults={{ content: "location" }}
          />
          <Elements.Region
            name="location_content"
            group="content"
            className="formkit-content"
          >
            <Elements.Content
              defaults={{
                content: "Provide the location for the event"
              }}
            />
          </Elements.Region>
          <Elements.Heading
            name="form_header"
            group="label"
            tag={"h2"}
            className="formkit-content-label"
            defaults={{ content: "request invite" }}
          />
          <Elements.Region
            name="form_content"
            group="content"
            className="formkit-content"
          >
            <Elements.Content
              defaults={{
                content: "Enter your email address to request an invite."
              }}
            />
          </Elements.Region>

          <Elements.Form>
            <Elements.Errors />
            <Elements.CustomFields>
              <Elements.AddFieldButton />
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "request" }}
              />
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            name="disclaimer"
            defaults={{
              content: "We respect your privacy. Unsubscribe at any time."
            }}
          />
          <Elements.BuiltWith background="page" />
        </div>
        <div className="formkit-images">
          <div className="formkit-images__item">
            <Elements.Image name="image_one" size={{ w: 500, h: 350 }} />
          </div>
          <div className="formkit-images__item">
            <Elements.Image name="image_two" size={{ w: 500, h: 350 }} />
          </div>
          <div className="formkit-images__item">
            <Elements.Image name="image_three" size={{ w: 500, h: 350 }} />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Linden.style = "landing_page"
Linden.categories = ["Event"]
Linden.thumbnail = ""
Linden.preview = "https://demo.ck.page/linden"
Linden.fields = [
  {
    name: "email_address",
    label: "email"
  }
]

export default createTemplate(Linden, { name: "Linden" })
